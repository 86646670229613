/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { rhythm } from "../utils/typography"
import styled from "styled-components"

const JobPosition = styled.div`
  font-size: 12px;
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FIXED)
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
            job {
              show
              position
              company {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
      }}
    >
      <GatsbyImage
        image={data.avatar.childImageSharp.gatsbyImageData}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          width: 100,
          height: 100,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
          objectFit: "contain",
        }}
      />
      <div>
        <div>
          <strong>{author.name}</strong>{" "}
          <JobPosition>
            {author.job.position} @{" "}
            <a
              rel="noopener noreferrer"
              target="_BLANK"
              href={author.job.company.url}
            >
              {author.job.company.title}
            </a>
          </JobPosition>
          <p>{author.summary}</p>
        </div>
      </div>
    </div>
  )
}

export default Bio
