import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Navigation from "../components/navigation"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { rhythm } from "../utils/typography"

const Articles = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allContentfulBlogPost.edges
  const social = data.site.siteMetadata.social

  return (
    <Layout location={location} title={siteTitle} social={social}>
      <Seo title="Articles" />
      <Navigation />
      <div style={{ marginTop: "-30px" }}>
        {posts.map(({ node }) => {
          const title = node.title || node.slug
          return (
            <article key={node.slug}>
              <header>
                <h3
                  style={{
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link
                    replace={true}
                    style={{ boxShadow: `none` }}
                    to={`/${node.slug}`}
                  >
                    > {title}
                  </Link>
                </h3>
                <small>{node.date}</small>
              </header>
              <section>
                <p>{node.description}</p>
              </section>
            </article>
          )
        })}
      </div>
      <hr />
      <Bio />
    </Layout>
  )
}

export default Articles

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          title
          url
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          description
          title
          date(formatString: "MMMM Do, YYYY")
          slug
        }
      }
    }
  }
`
